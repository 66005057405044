import 'babel-polyfill';
import 'reflect-metadata';
import {
  Platform,
} from '@piwikpro/platform';
import { PPMSCrate } from './PPMSCrate';

const platform = new Platform();

platform.run(PPMSCrate as any);

import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Spinner } from '@piwikpro/ui-components/feedback';
import { Branding } from '@piwikpro/ui-components/navigation';
import { withInject } from '@piwikpro/platform';
import DefaultModuleRedirector from './DefaultModuleRedirector';

class PPMS extends React.Component<any> {
  render() {
    const {
      config,
      jwt,
      history,
      Secured,
      Administration,
      UserPanel,
      Info,
    } = this.props;

    return (
      <Secured>
        <ConnectedRouter history={history}>
          <Branding config={{ jwt }}>
            <Suspense fallback={<Spinner className="x-large" fullscreen />}>
              <Switch>
                <Route exact path="/" component={DefaultModuleRedirector} />
                <Route path={config.get('USER_PANEL_BASE_URL')} component={UserPanel} />
                <Route
                  path={config.get('ADMINISTRATION_BASE_URL')}
                  component={Administration}
                />
                <Route path={config.get('INFO_BASE_URL')} component={Info} />
                <Route
                  path={config.get('SETTINGS_BASE_URL')}
                  render={props => (
                    <Redirect
                      from={props.match.url}
                      to={config.get('ADMINISTRATION_BASE_URL')}
                    />
                  )}
                />
              </Switch>
            </Suspense>
          </Branding>
        </ConnectedRouter>
      </Secured>
    );
  }
}

const mapStateToProps = state => ({
  jwt: state.session.jwt,
});

export default withInject({
  config: 'config',
  history: 'RouterCrate.history',
  Secured: 'AuthCrate.components.Secured',
  Administration: 'AdministrationCrate.components.Administration',
  UserPanel: 'UserPanelCrate.components.UserPanel',
  Info: 'InfoCrate.components.Info',
})(connect(mapStateToProps, null)(PPMS));

import { Service, inject } from '@piwikpro/platform';
import { Interceptable, IRequest, NextFunction } from '@piwikpro/http-crate/interfaces';
import { Notifications } from '@piwikpro/notification-crate';

@Service()
export class MaintenanceInterceptor implements Interceptable {
  constructor(
    @inject('NotificationCrate.notifications') private readonly notifications: Notifications,
  ) {}

  async intercept<T>(req: Required<IRequest<T>>, next: NextFunction): Promise<T> {
    let resp;

    try {
      resp = await next(req);
    } catch (err: any) {
      const maintenanceModeEnabled = err.headers?.get('X-Reason') === 'maintenance-mode';
      if (err.name === 'ServiceUnavailableError' && maintenanceModeEnabled) {
        this.notifications.push({
          // agreed on UI sync to hardcode that instead of doing hard backport of statics-ui
          // for single key and single, temporary UI state (on Rancher)
          message: 'Sorry, this action can\'t be performed now. Try again when the product maintenance is finished.',
          status: 'error',
          dismissible: true,
          autoDismiss: 5,
        });
      }

      throw err;
    }

    return resp;
  }
}

import React from 'react';
import { connect } from 'react-redux';
import {
  withInject,
} from '@piwikpro/platform';
import {
  SplashScreen,
  View,
  Root,
} from '@piwikpro/ui-components/structures';
import { Spinner } from '@piwikpro/ui-components/feedback';

const mapStateToProps = (state: any) => ({
  defaultModuleId: state.session.defaultModuleId,
  userId: state.session.userId,
});

class DefaultModuleRedirector extends React.Component<any> {
  async componentDidMount() {
    const {
      organization,
      defaultModuleId,
      config,
      userId,
    } = this.props;
    let redirectUrl;

    if (defaultModuleId === null) return;

    try {
      const {
        data: {
          attributes: {
            apps,
            common,
          },
        },
      } = await organization.fetchConfig(userId);

      redirectUrl = [...apps, ...common].find(
        element => element.id === defaultModuleId,
      ).href;
    } catch (err) {
      redirectUrl = config.get('DEFAULT_MODULE_URL_FALLBACK');
    }

    document.location.replace(`${document.location.origin}${redirectUrl}`);
  }

  render() {
    const {
      defaultModuleId,
      ProductBar,
    } = this.props;

    if (defaultModuleId === null) {
      return (
        <Root>
          <Root.TopSection>
            <div id="product-bar">
              <ProductBar />
            </div>
          </Root.TopSection>
          <View fullHeight>
            <SplashScreen type="noPrivilegesAllModules" />
          </View>
        </Root>
      );
    }

    return <Spinner className="x-large" fullscreen />;
  }
}

export default withInject({
  organization: 'OrganizationCrate.organization',
  ProductBar: 'OrganizationCrate.components.ProductBar',
  config: 'config',
})(connect(mapStateToProps, null)(DefaultModuleRedirector));

import React from 'react';
import { connect } from 'react-redux';
import { PPMSSnippet } from '@piwikpro/ui-components/ppms-snippet';

const Snippet: any = PPMSSnippet;

export class PPMSSnippetPanel extends React.Component<any> {
  render() {
    const { jwt } = this.props;

    if (!jwt) return null;

    return (
      <Snippet jwt={jwt} />
    );
  }
}

const mapStateToProps = state => ({
  jwt: state.session.jwt,
});

export default connect(mapStateToProps, null)(PPMSSnippetPanel);
